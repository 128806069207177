import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssociatedOrgsService } from './associated-orgs.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [AssociatedOrgsService],
})
export class AssociatedOrgsModule {}
