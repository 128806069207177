import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pem',
})
export class PemPipe implements PipeTransform {
    constructor() {}

    transform(pem: string) {
        return pem
            .replace(/-----BEGIN[A-Z ]+-----/, '')
            .replace(/-----END[A-Z ]+-----/, '')
            .replace('\n', '')
            .trim();
    }
}
