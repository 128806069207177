import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'spacedNumber',
})
export class SpacedNumberPipe implements PipeTransform {
    transform(input: number | string): string {
        if (input === undefined || input === null) {
            return '';
        }

        const parts = input.toString().split('.');
        const integerPart = parts[0];
        const fractionPart = parts.length > 1 ? '.' + parts[1] : '';

        return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + fractionPart;
    }
}
