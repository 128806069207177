import { Component, Directive, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'rui-field',
    template: `
        <ng-content *ngIf="label === undefined; else labelBlock" select="rui-field-label"></ng-content>
        <ng-template #labelBlock>
            <rui-field-label>{{ label }}</rui-field-label>
        </ng-template>

        <ng-container *ngIf="loading">
            <rui-field-value>
                <ngx-skeleton-loader [theme]="{ 'vertical-align': 'middle', 'margin-bottom': 0 }"></ngx-skeleton-loader>
            </rui-field-value>
        </ng-container>
        <ng-container *ngIf="!loading">
            <ng-content *ngIf="value === undefined; else valueBlock" select="rui-field-value"></ng-content>
            <ng-template #valueBlock>
                <rui-field-value [class.italic]="value === null">{{ getValue() }}</rui-field-value>
            </ng-template>
        </ng-container>
    `
})
export class RuiFieldComponent {
    @HostBinding('class') classes = 'mb-5 block';
    @Input() label: string;
    @Input() value: string | number | Date;
    @Input() noValue = '---';
    @Input() loading = false;

    getValue() {
        if (typeof this.value == 'string') {
            return this.value || this.noValue;
        } else {
            return this.value ?? this.noValue;
        }
    }
}

/**
 *  Label for field
 */
@Directive({
    selector: `rui-field-label`
})
export class RuiFieldLabelComponent {
    @HostBinding('class') classes = 'text-sm text-gray-500 flex items-center';
}

/**
 *  Value for field
 */
@Directive({
    selector: `rui-field-value`
})
export class RuiFieldValueComponent {
    @HostBinding('class') classes = 'pb-1.5 pt-px block whitespace-pre-wrap';
}
