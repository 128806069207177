import { Component, Directive, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'rui-card',
    encapsulation: ViewEncapsulation.None,
    template: `
        <div class="flex flex-col mb-6">
            <ng-content></ng-content>
        </div>
    `,
})
export class RuiCardComponent {
    @HostBinding('class') classes = 'flex flex-col';
}

/**
 *  Header for content block
 */
@Directive({
    selector: `rui-card-header`,
})
export class RuiCardHeaderComponent {
    @HostBinding('class') classes = 'flex flex-col mb-6';
}

/**
 *  Title for content block
 */
@Directive({
    selector: `rui-card-title`,
})
export class RuiCardTitleComponent {
    @HostBinding('class') classes = 'text-xl sm:text-2xl tracking-tight ';
}

/**
 *  Subtitle for content block
 */
@Directive({
    selector: `rui-card-subtitle`,
})
export class RuiCardSubtitleComponent {
    @HostBinding('class') classes = 'text-secondary';
}
