import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitIntoBlocks'
})
export class SplitIntoBlocksPipe implements PipeTransform {
    transform(value: string, blockSize: number): string {
        const blocks: string[] = [];
        while (value && value.length > 0) {
            blocks.push(value.substring(0, blockSize));
            value = value.substring(blockSize, value.length);
        }
        return blocks.join(' ');
    }
}
