import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthConfig, AUTH_CONFIG } from '@ridango/auth';
import { Observable } from 'rxjs';
import { UserActivityService } from './user-activity.service';

@Injectable()
export class UserActivityInterceptor implements HttpInterceptor {
    constructor(
        @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
        private userActivityService: UserActivityService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url !== this.authConfig.sessionUrl) {
            this.userActivityService.activityDetected();
        }

        return next.handle(request);
    }
}
