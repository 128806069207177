import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PrivilegesService {
    private _privileges$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

    setPrivileges(privs: string[]) {
        this._privileges$.next(privs)
    }

    get privileges$() {
        return this._privileges$.asObservable().pipe(filter((user) => user !== null));
    }

    can(privilege: string): boolean {
        if (this._privileges$.getValue() == null) {
            return false;
        } else {
            return this._privileges$.getValue().includes(privilege);
        }
    }

    can$(privileges: string[]): Observable<boolean> {
        return this.privileges$.pipe(
            map((allPrivs) => {
                return privileges.every((p) => allPrivs.includes(p));
            })
        );
    }
}
