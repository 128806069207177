import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PrivilegesService } from '@ridango/auth';
import { map, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PrivilegesGuard implements CanActivate {
    constructor(private router: Router, private privileges: PrivilegesService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        if (route?.data?.privileges) {
            return this.privileges
                .can$(route?.data?.privileges)
                .pipe(map((result) => result || this.router.createUrlTree(['/'])));
        }
        return of(true);
    }
}
