import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

@Injectable({
    providedIn: 'root',
})
export class LaunchDarklyTestingService {
    getFlag(flagName: string, defaultValue: boolean = false): Observable<LDFlagValue> {
        return of(defaultValue);
    }
}
