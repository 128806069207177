export class FormUtils {
    static notEmptyValuesOnly<TObject extends Record<string, unknown>>(
        values: TObject,
        emptyValues = [null, '', undefined]
    ): TObject {
        return Object.keys(values)
            .filter((key) => !emptyValues.includes(values[key]?.toString()))
            .reduce((all, key) => ({ ...all, [key]: values[key] }), {} as TObject);
    }

    static emptyValuesOnly<TObject extends Record<string, unknown>>(
        values: TObject,
        emptyValues = [null, '', undefined]
    ): TObject {
        return Object.keys(values)
            .filter((key) => emptyValues.includes(values[key]?.toString()))
            .reduce((all, key) => ({ ...all, [key]: values[key] }), {} as TObject);
    }
}
