<div class="filters-toolbar">
    <div class="filter-title-container">
        <div *ngIf="totalItems >= 0" class="filter-title">{{ 'common.totalItems' | translate }}: {{ totalItems }}</div>
        <div><ng-content select="[info]"></ng-content></div>
    </div>
    <div class="block md:hidden">
        <button mat-button (click)="toggleFilters()">
            <mat-icon *ngIf="!showFilters">filter_alt</mat-icon>
            <mat-icon *ngIf="showFilters">close</mat-icon>
            {{ 'common.filter' | translate }}
        </button>
    </div>
</div>

<div *ngIf="showFilters" class="p-4">
    <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-3 w-full">
        <ng-content></ng-content>
    </div>
</div>
