<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <mat-drawer-container class="flex-auto h-full">
        <!-- Drawer -->
        <mat-drawer
            class="w-72 dark:bg-gray-900"
            position="start"
            [autoFocus]="false"
            [mode]="drawerMode"
            [opened]="!!pageDrawer && drawerOpened"
            #matDrawer>
            <ng-content select="rui-page-drawer"></ng-content>
        </mat-drawer>

        <!-- Drawer content -->
        <mat-drawer-content class="flex flex-col relative">
            <!-- Header -->
            <div
                class="
                    flex flex-col
                    flex-0
                    sm:justify-between
                    pt-6
                    px-6
                    dark:bg-transparent
                ">

                <div class="flex items-center">
                    <div *ngIf="showBackButton" class="flex pr-4">
                        <a
                            class="inline-flex items-center -ml-1.5 text-secondary font-medium"
                            [routerLink]="backRoute">
                            <mat-icon
                                class="icon-size-6 text-secondary text-link-500"
                                [svgIcon]="'heroicons_solid:arrow-left'"></mat-icon>
                        </a>
                    </div>
                    <div class="flex-grow min-w-0">
                        <!-- Breadcrumbs -->
                        <div *ngIf="breadcrumbs.length">
                            <div class="text-sm text-gray-500 hidden sm:flex flex-wrap items-center">
                                <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i; first as isFirst">
                                    <div *ngIf="isFirst; else elseTemplate">
                                        <a
                                            *ngIf="breadcrumb.link; else plainCrumb"
                                            [routerLink]="breadcrumb.link"
                                            class="whitespace-nowrap text-link">
                                            {{ breadcrumb.title }}
                                        </a>
                                        <ng-template #plainCrumb>
                                            <span class="whitespace-nowrap">{{ breadcrumb.title }}</span>
                                        </ng-template>
                                    </div>
                                    <ng-template #elseTemplate>
                                        <div class="flex items-center ml-1 whitespace-nowrap">
                                            <span class="text-black">/</span>
                                            <a
                                                *ngIf="breadcrumb.link; else plainCrumb"
                                                [routerLink]="breadcrumb.link"
                                                class="text-link ml-1">
                                                {{ breadcrumb.title }}
                                            </a>
                                            <ng-template #plainCrumb>
                                                <span class="ml-1">{{ breadcrumb.title }}</span>
                                            </ng-template>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </div>
                        </div>
                        <!-- Heading/Title -->
                        <div class="flex">
                            <h2 class="text-xl sm:text-2xl tracking-tight leading-7 sm:leading-10 truncate">
                                <ng-content select="rui-page-heading"></ng-content>
                            </h2>
                        </div>
                    </div>
                    <!-- Actions -->
                    <div class="flex flex-shrink-0 items-center sm:mt-0 sm:ml-4">
                        <ng-content select="rui-page-actions"></ng-content>
                        <button *ngIf="!!pageDrawer" class="ml-3" [class.md:hidden]="!canToggleDrawer" mat-icon-button
                                (click)="matDrawer.toggle()">
                            <mat-icon svgIcon="heroicons_outline:menu"></mat-icon>
                        </button>
                    </div>
                </div>

                <ng-content select="rui-page-topnav"></ng-content>

            </div>

            <!-- Main -->
            <div class="flex-auto p-6">
                <!-- CONTENT GOES HERE -->
                <ng-content select="rui-page-content"></ng-content>

            </div>
            <ng-content select="rui-page-footer"></ng-content>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
