import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@ridango/auth';
import { ActivatedRoute } from '@angular/router';
import { map, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    private destroyed$ = new Subject();
    private redirectURL?: string;

    constructor(private _authService: AuthService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.queryParams
            .pipe(
                takeUntil(this.destroyed$),
                map((params) => params['redirectURL'])
            )
            .subscribe((redirectURL) => {
                this.redirectURL = redirectURL
            });
    }

    signIn() {
        this._authService.authenticate(this.redirectURL);
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }
}
