import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { IStorageService } from '../storage/storage.interface';
import { Pagination } from './pagination';
import { SortState } from './sorting';

export interface IListmanager<TResponseContent, TQueryParams> {
    list: TResponseContent[];
    pagination: Pagination;
    pageSizeOptions: number[];
    sort: SortState;

    cachedFilter: Partial<TQueryParams>;
    filter: Partial<TQueryParams>;

    loading: boolean;

    isFilterEmpty: boolean;
    isFilterNotEmpty: boolean;

    storage: IStorageService | null;

    handlePageChange(event: PageEvent): void;
    handleSortChange(event: Sort): void;

    fetchData(): void;
    reloadStore(): void;
}

export interface IListmanagerOptions {
    /**
     * @description (Optional) Set initial pagination.
     */
    pagination?: Pagination;

    /**
     * @description (Optional) Set initial sorting.
     */
    sort?: SortState;

      /**
     * @description (Optional) Set initial filtering.
     */
    filter?: any;

    /**
     * @description (Optional) Provide context through prefix string.
     * Can be useful when storing to session- or localStorage.
     */
    storagePrefix?: string;

    /**
     * @description (Optional) Provide storage service to persist filter, sorting and pagination states.
     */
    storage?: IStorageService;
}