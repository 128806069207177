import { IListmanager, IListmanagerOptions } from './list-manager.interface';
import { Pagination } from './pagination';
import { ESortDirection, SortState } from './sorting';

export class ListManagerStore<TResponseContent, TQueryParams> {
    private listmanager: IListmanager<TResponseContent, TQueryParams>;
    private prefix: string;

    constructor(listmanager: IListmanager<TResponseContent, TQueryParams>, options?: Partial<IListmanagerOptions>) {
        this.listmanager = listmanager;
        this.prefix = options?.storagePrefix ?? '';
    }

    static getStoreKey(prefix: string, key: string) {
        if (prefix) return prefix + '_' + key;
        return key;
    }

    save() {
        if (!this.listmanager.storage) return;

        this.listmanager.storage.setItem(this.getStoreKey('sort'), this.listmanager.sort.key);
        this.listmanager.storage.setItem(this.getStoreKey('direction'), this.listmanager.sort.direction);
        this.listmanager.storage.setItem(this.getStoreKey('page'), this.listmanager.pagination.page + 1);
        this.listmanager.storage.setItem(this.getStoreKey('size'), this.listmanager.pagination.size);
        this.listmanager.storage.setItem(this.getStoreKey('filter'), this.listmanager.filter);
    }

    load() {
        if (!this.listmanager.storage) return;

        const sort = this.listmanager.storage.getItem<string>(this.getStoreKey('sort'));
        const direction = this.listmanager.storage.getItem<ESortDirection>(this.getStoreKey('direction'));
        if (sort && direction) {
            this.listmanager.sort = new SortState(sort, direction);
        }

        const page = this.listmanager.storage.getItem<number>(this.getStoreKey('page')) - 1;
        const size = this.listmanager.storage.getItem<number>(this.getStoreKey('size'));
        if (page != null && size != null) {
            this.listmanager.pagination = new Pagination(page, size);
        }

        const filter = this.listmanager.storage.getItem<Partial<TQueryParams>>(this.getStoreKey('filter'));
        if (filter) {
            this.listmanager.cachedFilter = {
                ...this.listmanager.cachedFilter,
                ...filter,
            };
        }
    }

    private getStoreKey(key: string) {
        return ListManagerStore.getStoreKey(this.prefix, key);
    }
}
