<div class="notification-bar {{ notification.type }}" *ngIf="notification?.display">
    <div class="icon">
        <img src="assets/media/icon-{{notification.type}}.svg" [attr.alt]="notification.type" />
    </div>
    <div class="content">
        <p [innerHTML]="notification.info | translate"></p>
    </div>
    <div class="close">
        <button mat-icon-button aria-label="Close" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>