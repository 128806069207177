import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { IStorageService } from '../storage/storage.interface';
import { FormUtils } from './form-utils';
import { IListmanager, IListmanagerOptions } from './list-manager.interface';
import { ListManagerStore } from './list-manager.store';
import { IPaginatedQueryParams } from './paginated-query-params.interface';
import { Pagination } from './pagination';
import { ESortDirection, SortState } from './sorting';

interface IServerSideListManagerOptions extends IListmanagerOptions {
    dynamicSize?: boolean;
}

interface Page<TContent> {
    last: boolean;
    content: TContent[];
    totalElements: number;
    pageable: {
        pageNumber: number;
    };
}

export class ServerSideListManager<TResponseContent, TQueryParams extends IPaginatedQueryParams>
    implements IListmanager<TResponseContent, TQueryParams>
{
    list: TResponseContent[] = [];
    pagination: Pagination;
    last: boolean;
    pageSizeOptions = [20, 50, 100, 500];
    sort: SortState;
    cachedFilter: Partial<TQueryParams> = {};
    private _previousPageSize = 0;

    storage: IStorageService = null;
    private store: ListManagerStore<TResponseContent, TQueryParams>;
    loading = false;

    requestFn: (params: Partial<TQueryParams>) => Observable<Page<TResponseContent>>;

    constructor(
        requestFn: (params: Partial<TQueryParams>) => Observable<Page<TResponseContent>>,
        options?: IServerSideListManagerOptions
    ) {
        this.requestFn = requestFn;
        this.sort = options?.sort ?? new SortState('', ESortDirection.Asc);
        this.pagination = options?.pagination ?? new Pagination(0, 50);
        this.cachedFilter = options?.filter ?? {};

        this.storage = options?.storage;

        this.store = new ListManagerStore(this, options);
        this.store.load();
        this.store.save();
    }

    reloadStore() {
        this.store.load();
        this.store.save();
    }

    handlePageChange(event: PageEvent): void {
        this.pagination.update(event);
        this.store.save();
        this.fetchData();
    }

    handleSortChange(event: Sort): void {
        this.pagination.page = 0;

        this.sort.update(event);
        this.store.save();
        this.fetchData();
    }

    get totalElements(): number {
        if (this.last) {
            return this.pagination.page * this.pagination.size + this.list.length;
        }
        return Number.POSITIVE_INFINITY;
    }

    get approximatePageSize() {
        if (this._previousPageSize === 0) {
            return 5;
        }
        return this._previousPageSize;
    }

    get isFilterEmpty(): boolean {
        return (
            this.filter === null ||
            this.filter === undefined ||
            Object.keys(FormUtils.notEmptyValuesOnly(this.filter)).length === 0
        );
    }

    get isFilterNotEmpty(): boolean {
        return !this.isFilterEmpty;
    }

    get filter(): Partial<TQueryParams> {
        return this.cachedFilter;
    }

    set filter(value: Partial<TQueryParams>) {
        this.pagination.page = 0;
        this.cachedFilter = value;
        this.store.save();
    }

    get queryParams(): Partial<TQueryParams> {
        let output: Partial<TQueryParams> = {};

        output.page = this.pagination.page.toString();
        output.size = this.pagination.size.toString();

        if (this.sort) {
            output.sort = [this.sort.key];
            output.direction = this.sort.direction?.toLocaleUpperCase();
        }

        if (this.filter != null) {
            const notEmpty = FormUtils.notEmptyValuesOnly(this.filter);

            output = { ...output, ...notEmpty };
        }

        return output;
    }

    fetchData() {
        this.list = [];
        this.loading = true;
        this.requestFn(this.queryParams).subscribe((data: Page<TResponseContent>) => {
            this.list = data?.content;
            this._previousPageSize = this.list.length;
            this.pagination.page = data.pageable.pageNumber;
            this.pagination.totalElements = data.totalElements;
            this.last = data.last;
            this.loading = false;
        });
    }

    fetchDataWithoutPagination() {
        this.requestFn(this.queryParams).subscribe((data: Page<TResponseContent>) => {
            this.list = data?.content;
            this.filter = null;
            this.pagination = null;
            this.last = data.last;
        });
    }

    fetchDataWithoutReloadingComponent() {
        this.requestFn(this.queryParams).subscribe((data: Page<TResponseContent>) => {
            this.list = data?.content;
            this._previousPageSize = this.list.length;
            this.pagination.page = data.pageable.pageNumber;
            this.pagination.totalElements = data.totalElements;
            this.last = data.last;
        });
    }

    resetFilter(value?: Partial<TQueryParams>) {
        this.filter = value ?? {};
    }

    reset() {
        this.pagination = new Pagination(0, 50);
        this.resetFilter();
        this.sort = new SortState('', ESortDirection.Asc);
    }

    clearResults() {
        this.list = [];
    }
}
