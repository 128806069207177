import { Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RuiPageComponent } from '@ridango/ui/page/page.component';
import { breadcrumbConfig, Breadcrumbs } from '../../navigation.models';
import { Breadcrumb } from '../../widgets/breadcrumbs/breadcrumbs/breadcrumbs.component';

@Directive({
    selector: '[paymentsBreadcrumbs]',
})
export class BreadcrumbsDirective {
    constructor(
        private route: ActivatedRoute,
        private breadcrumbsComponent: RuiPageComponent,
        private translate: TranslateService
    ) {
        const breadcrumbs = this.route.snapshot.data?.breadcrumbs?.map((crumb: Breadcrumbs) => {
            const config = this.findConfig(crumb);
            return {
                title: this.translate.instant(config.title),
                link: config.link,
            };
        });

        if (!breadcrumbs) {
            return;
        }

        this.removeLinkFromLast(breadcrumbs);
        this.breadcrumbsComponent.breadcrumbs = breadcrumbs;

        if (breadcrumbs.length >= 2) {
            this.breadcrumbsComponent.backRoute = breadcrumbs[breadcrumbs.length - 2].link;
        }
    }

    private findConfig(crumb: Breadcrumbs) {
        return breadcrumbConfig.find((config) => config.crumb === crumb);
    }

    private removeLinkFromLast(breadcrumbs: Breadcrumb[]) {
        breadcrumbs[breadcrumbs.length - 1].link = undefined;
    }
}
