import { Component, Input } from '@angular/core';

@Component({
    selector: 'payments-loading-button',
    template: `
        <ngx-skeleton-loader
            [theme]="{
                height: '40px',
                width: '100px',
                'border-radius': '20px',
                'margin-bottom': '0px'
            }"></ngx-skeleton-loader>
    `,
})
export class LoadingButtonComponent {}

@Component({
    selector: 'payments-loading-data',
    template: `
        <div class="grid grid-cols-1 sm:grid-cols-4 gap-x-8">
            <ngx-skeleton-loader class="sm:col-span-2" count="6"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="sm:col-span-1" count="4"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="sm:col-span-1 sm:col-start-1" [count]="extraRows"></ngx-skeleton-loader>
        </div>
    `,
})
export class LoadingDataComponent {
    @Input() extraRows = 0;
}
