import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';

export class Pagination {
    private _page$ = new BehaviorSubject(0);

    size: number;
    totalElements?: number;

    constructor(page: number, size: number) {
        this.page = page;
        this.size = size;
    }

    update(event: PageEvent) {
        this.page = event.pageIndex;
        this.size = event.pageSize;
    }

    /**
     * zero-based page number
     */
    set page(value: number) {
        this._page$.next(value);
    }

    get page() {
        return this._page$.value;
    }

    /**
     * observable of zero-based page number
     */
    get page$() {
        return this._page$.asObservable();
    }
}
