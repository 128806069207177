import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { AuthService } from '@ridango/auth';
import { filter, interval, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserActivityService {
    private readonly SESSION_DURATION_IN_SECONDS = 15 * 60 - 30;
    private readonly PROMPT_USER_FOR_EXTENSION = this.SESSION_DURATION_IN_SECONDS - 60;
    private readonly KEY_LAST_USER_ACTIVITY_AT = 'last_user_activity_at';

    private modal: MatDialogRef<FuseConfirmationDialogComponent> | undefined;
    private lastUserActivityAt: number;
    private userActivity$ = new Subject();

    constructor(private authService: AuthService, private confirmationService: FuseConfirmationService, private router: Router) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => this.activityDetected());

        window.addEventListener('storage', (event) => {
            if (event.key === 'last_user_activity_at') {
                this.lastUserActivityAt = Number(event.newValue);
                this.checkAge();
            }
        });

        interval(1000)
            .subscribe(() => this.checkAge());

        this.userActivity$
            .pipe(debounceTime(2000))
            .subscribe(() => this.extendSession());

        this.storeUserActivity();
        this.checkAge();
    }

    activityDetected() {
        this.userActivity$.next(null);
        this.storeUserActivity();
        this.checkAge();
    }

    private checkAge() {
        if (!this.authService.isAuthenticated) {
            this.modal?.close();
            return;
        }

        const age = this.calculateActivityAge();

        if (age >= this.PROMPT_USER_FOR_EXTENSION) {
            if (!this.modal) {
                this.promptUserForSessionExtension();
            }

            this.updateModalMessage(
                `Your session is going to expire in ${
                    this.SESSION_DURATION_IN_SECONDS - age
                } seconds. Continue?`
            );
        } else if (this.modal) {
            this.modal.close();
        }

        if (age >= this.SESSION_DURATION_IN_SECONDS) {
            this.endSession();
            return;
        }
    }

    private calculateActivityAge() {
        let age = Math.floor((Date.now() - this.lastUserActivityAt) / 1000);

        if (age < 0) {
            age = 0;
        }

        return age;
    }

    private storeUserActivity() {
        this.lastUserActivityAt = Date.now();

        localStorage.setItem(this.KEY_LAST_USER_ACTIVITY_AT, String(this.lastUserActivityAt));
    }

    private promptUserForSessionExtension() {
        this.modal?.close();

        this.modal = this.confirmationService.open({
            title: 'Session',
            icon: {
                show: false,
            },
            actions: {
                confirm: {
                    label: 'Yes',
                    color: 'primary',
                },
                cancel: {
                    label: 'No',
                },
            },
        });

        this.modal.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.extendSession();
                this.activityDetected();
            }

            if (result === 'cancelled') {
                this.endSession();
            }

            this.modal = undefined;
        });
    }

    private extendSession() {
        this.authService.currentSessionRequest().subscribe();
    }

    private endSession() {
        this.authService.logout();
        this.modal?.close();
    }

    private updateModalMessage(message: string) {
        if (this.modal) {
            this.modal.componentInstance.data.message = message;
        }
    }
}
