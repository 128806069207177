import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'payments-clear-filters',
    templateUrl: './clear-filters.component.html',
    styleUrls: ['./clear-filters.component.scss'],
})
export class ClearFiltersComponent {
    @Output() clear = new EventEmitter<void>();
}
