import { Component, Input } from '@angular/core';

@Component({
    selector: 'payments-actions-wrapper',
    templateUrl: './actions-wrapper.component.html',
    styleUrls: ['./actions-wrapper.component.scss'],
})
export class ActionsWrapperComponent {
    @Input() small = false; // small and label fields are temporarily unused
    @Input() label: string;
    @Input() isAllowed = true;
}
