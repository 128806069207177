import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssociatedOrgsService {

    private _orgs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

    // constructor() {
    //     of(['root']).subscribe(items => {this._orgs$.next(items)});
    // }


    setOrgs(orgs) {
         this._orgs$.next(orgs)
    }

    get orgs$() {
        return this._orgs$.asObservable().pipe(filter((org) => org !== null));
    }

    has(org: string): boolean {
        if (this._orgs$.getValue() == null) {
            return false;
        } else {
            return this._orgs$.getValue().includes(org);
        }
    }

    has$(orgs: string[]): Observable<boolean> {
        return this.orgs$.pipe(
            map((allOrgs) => {
                return orgs.every((org) => allOrgs.includes(org));
            })
        );
    }
}
