import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { LaunchDarklyService } from './launchdarkly.service';
import { Subscription } from 'rxjs';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

/**
 * Code mostly copy-pasted from angular AsyncPipe
 */

@Pipe({ name: 'featureFlag', pure: false })
export class FeatureFlagPipe implements OnDestroy, PipeTransform {
    private _latestValue: LDFlagValue = null;
    private _flagName: string | null = null;
    private _subscription: Subscription | null = null;

    constructor(private _ref: ChangeDetectorRef, private LD: LaunchDarklyService) {}

    ngOnDestroy(): void {
        if (this._subscription) {
            this.dispose();
        }
    }

    transform<T>(flagName: string | null | undefined, defaultValue?): T | null {
        // If this is first call we go and subscribe
        if (!this._flagName) {
            if (flagName) {
                this.subscribe(flagName, defaultValue);
            }
            return this._latestValue;
        }

        //If the flagname has changed for some reason we have to start again
        if (flagName !== this._flagName) {
            this.dispose();
            return this.transform(flagName);
        }

        return this._latestValue;
    }

    private subscribe(flagName: string, defaultValue?): void {
        this._flagName = flagName;
        this._subscription = this.LD.getFlag(flagName, defaultValue).subscribe((flagValue) => {
            this.updateLatestValue(flagName, flagValue);
        });
    }

    private dispose(): void {
        this._subscription.unsubscribe();
        this._latestValue = null;
        this._subscription = null;
        this._flagName = null;
    }

    private updateLatestValue(flagName: string, value: LDFlagValue): void {
        //Update only if the value that came back matches the flagName currently acgive
        if (flagName === this._flagName) {
            this._latestValue = value;
            this._ref.markForCheck();
        }
    }
}
