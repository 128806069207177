import { Component, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { SnackbarInfo } from '../snackbar.model';

@Component({
    selector: 'payments-notification-bar',
    templateUrl: './notification-bar.component.html',
    styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent implements OnInit {
    @Input() notification: SnackbarInfo;

    ngOnInit(): void {
        if (this.notification?.timeout) {
            timer(this.notification.timeout).subscribe(() => {
                this.close();
            });
        }
    }

    close() {
        this.notification.display = false;
    }
}
