import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minifyToken',
})
export class MinifyTokenPipe implements PipeTransform {
    transform(token: string): string {
        if (!token) return token;
        if (token?.length <= 16) return token;

        return token?.substring(0, 8) + '...' + token?.substring(token.length - 4, token.length);
    }
}
