import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, filter, startWith, tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSourcePageEvent } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

import { isEqual, mapValues, pickBy } from 'lodash-es';

/*
This is the response the DataSource is looking for.
 */
export interface ApiResponse<T> {
    data: T,
    meta?: {
        totalElements?: number,
        size?: number
    }
}

/*
Query params passed to the method that provides the Observable data
 */
export interface DataSourceQueryParams {
    page?: {
        number?: number
        size: number
    },
    sort?: Sort,
    filter?: {
        [index: string]: boolean | string | string[];
    }
}

export const ALL_ITEMS = {page: {number: 0, size: Number.MAX_SAFE_INTEGER}}

export interface FilterData {
    [index: string]:  string | string[];
}

/*
Interface for the method that provides the data
 */
export interface DataSourceQueryFn<T> {
    (params?: DataSourceQueryParams): Observable<ApiResponse<T[]>>;
}

export class RuiTableDataSource<T> extends DataSource<T> {
    private itemsSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(true);
    private reloadSubject = new BehaviorSubject<DataSourceQueryParams>({});
    private reloadSubscription: Subscription | null = null;
    // Reference to the method that returns the data
    private sourceFunction: DataSourceQueryFn<T>;

    private _paginator: MatPaginator | null;
    private _sort: MatSort | null;
    private _filter: Observable<FilterData> | null;

    public loading$ = this.loadingSubject.asObservable();

    // Subscription that looks for any changes in paginator, filterForm or sort and triggers reloading of data
    _renderChangesSubscription: Subscription | null = null;

    constructor(SourceFn: DataSourceQueryFn<T>) {
        super();
        this.sourceFunction = SourceFn;
    }

    connect(): Observable<T[]> {
        // Set the subscriber in a Timeout so that all the stuff has time to load
        // Also debounce reload calls to avoid spamming the loadData method
        setTimeout(() => {
            this.reloadSubscription = this.reloadSubject
                .pipe(debounceTime(100))
                .subscribe((params) => this.loadData(params));
        }, 50);

        if (!this._renderChangesSubscription) {
            this._updateChangeSubscription();
        }

        return this.itemsSubject.asObservable();
    }

    disconnect(): void {
        this.itemsSubject.complete();
        this.loadingSubject.complete();

        this.reloadSubscription?.unsubscribe();
        this.reloadSubject.complete();

        this._renderChangesSubscription?.unsubscribe();
        this._renderChangesSubscription = null;
    }

    private loadData(params: DataSourceQueryParams) {
        this.loadingSubject.next(true);

        this.sourceFunction(params)
            .pipe(
                // Set the paginator length if it exists.
                tap((items) => {
                    if (this._paginator) this._paginator.length = items.meta.totalElements;
                }),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((items) => this.itemsSubject.next(items.data));
    }

    public refresh() {
        this.reloadSubject.next(this.reloadSubject.getValue())
    }

    /*
     * You can change the source function after initializing the DataSource
     * */
    get source() {
        return this.sourceFunction;
    }

    set source(sourceFn: DataSourceQueryFn<T>) {
        this.sourceFunction = sourceFn;
        this.reloadSubject.next(this.reloadSubject.getValue())
    }

    /*
     * Reference to the paginator component
     * */
    get paginator(): MatPaginator | null {
        return this._paginator;
    }

    set paginator(paginator: MatPaginator | null) {
        this._paginator = paginator;
        this._updateChangeSubscription();
    }

    /*
     * Reference to the sorting component
     * */
    get sort(): MatSort | null {
        return this._sort;
    }

    set sort(sort: MatSort | null) {
        this._sort = sort;
        this._updateChangeSubscription();
    }

    /*
     * Holds the reference to the filterForm
     * */
    get filter(): Observable<FilterData> | null {
        return this._filter;
    }

    set filter(filter: Observable<FilterData> | null) {
        this._filter = filter;
        this._updateChangeSubscription();
    }

    /*
     * This gets called whenever you add/remove sorting/filtering/pagination from the datasource
     * */
    _updateChangeSubscription() {
        // Observe when sort is changed or initialized.
        // Also set paginator to first page when it exists.
        const sortChange: Observable<Sort | null | void> = this._sort
            ? merge(this._sort.sortChange, this._sort.initialized).pipe(
                  tap(() => this._paginator && this._paginator.firstPage())
              )
            : of(null);

        // Observe when page is changed or paginator is initialized
        const pageChange: Observable<MatTableDataSourcePageEvent | null | void> = this._paginator
            ? merge(this._paginator.page, this._paginator.initialized)
            : of(null);

        // Observe when filterForm value changes
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filterChange: Observable<any | null | void> = this._filter
            ? this.filter.pipe(
                  // Trim all strings
                  map((values) => mapValues(values, (value) => (typeof value === 'string' ? value.trim() : value))),
                  // Remove all null and empty string values
                  map((values) => pickBy(values, (value) => value !== null && value !== '')),
                  // Debounce for 200ms
                  debounceTime(300),
                  // Don't reload if form unchanged
                  distinctUntilChanged(isEqual),
                  // Start with empty object, otherwise combineLatest wont be triggered
                  startWith({}),
                  // When filtering always show first page if paginator exists.
                  tap(() => this._paginator && this._paginator.firstPage())
              )
            : of(null);

        const change = combineLatest([sortChange, pageChange, filterChange]);

        // Unsubscribe the old Subscription to avoid mem leaks.
        this._renderChangesSubscription?.unsubscribe();
        this._renderChangesSubscription = change
            .pipe(filter(([a, b, c]) => a !== null || b !== null || c !== null))
            .subscribe(([, , filterValue]) => {
                const params: DataSourceQueryParams = {};

                if (this._paginator) {
                    params.page = {
                        number: this._paginator.pageIndex,
                        size: this._paginator.pageSize,
                    };
                }

                if (this._sort) {
                    params.sort = {
                        active: this._sort.active,
                        direction: this._sort.direction,
                    };
                }

                params.filter = filterValue;

                return this.reloadSubject.next(params);
            });
    }
}
