import { Component, Input } from '@angular/core';

export interface Breadcrumb {
    title: string;
    link?: string | any[];
}

@Component({
    selector: 'payments-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
    @Input() breadcrumbs: Breadcrumb[];
}
