import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[paymentsStickyShadow]', // eslint-disable-line
})
export class StickyDirective implements AfterViewInit {
    private element: ElementRef<HTMLTableElement>;
    private hasShadow: boolean;
    private tableContainer: HTMLElement;

    constructor(element: ElementRef) {
        this.element = element;
        this.hasShadow = false;
    }

    ngAfterViewInit(): void {
        this.tableContainer = this.element.nativeElement?.parentElement?.parentElement.parentElement.parentElement;
        // timeout required as ngAfterViewInit is sometimes too early to updateShadow
        setTimeout(() => {
            this.updateShadow();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.updateShadow();
    }

    private updateShadow() {
        const hasHorizontalScrollbar = this.tableContainer.scrollWidth > this.tableContainer.clientWidth;

        if (hasHorizontalScrollbar != this.hasShadow) {
            this.toggleShadow();
        }
    }

    private toggleShadow() {
        this.hasShadow = !this.hasShadow;
        if (this.hasShadow) {
            this.element.nativeElement.classList.add('action-col-shadow');
        } else {
            this.element.nativeElement.classList.remove('action-col-shadow');
        }
    }
}
