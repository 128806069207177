import { Component, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { GenericError } from '@auth0/auth0-spa-js';

@Component({
    templateUrl: './error.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ErrorComponent implements OnInit {
    constructor(@Optional() private auth0: AuthService) {}

    errorKey: string;
    description: string;

    ngOnInit() {
        this.auth0?.error$.subscribe((error: GenericError) => {
            this.errorKey = error.error;
            this.description = error.error_description;
        });
    }

    logOut() {
        this.auth0?.logout({ returnTo: window.location.origin });
    }
}
