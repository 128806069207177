import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { AuthModule } from '@ridango/auth';

@NgModule({
    declarations: [LoginComponent, ErrorComponent],
    imports: [CommonModule, LoginRoutingModule, MatButtonModule, AuthModule],
})
export class LoginModule {}
