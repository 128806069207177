import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivilegesService } from './privileges.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [PrivilegesService]
})
export class PrivilegesModule {
}
