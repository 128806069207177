<div class="absolute inset-0">
    <!-- Main -->
    <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10">
        <!-- @formatter:on -->
        <div
            class="mt-12 sm:mt-24 text-4xl md:text-7xl font-extrabold tracking-tight leading-tight md:leading-none text-center">
            Login failed
        </div>
        <div class="mt-2 text-lg md:text-xl font-medium tracking-tight text-center text-secondary">
            {{errorKey}} <br>
            <small>{{description}} </small>
        </div>
        <div class="mt-2">
            <a (click)="logOut()" class="text-link underline cursor-pointer">Log in using a different user.</a>
        </div>
    </div>
</div>
