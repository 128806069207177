export interface AuthSession {
    resource: AuthResource;
}

export interface AuthResource {
    id: number;
    created: string;
    lastModified: string;
    person: AuthPerson;
    status: string;
    authType: string;
    authToken: string;
    nonce: string;
    token: string;
    validTo: string;
    activeRole: AuthActiveRole;
}

export interface AuthPerson {
    id: number;
    name: string;
    clientId: string;
    created: string;
    lastModified: string;
    createdBy: string;
    lastModifiedBy: string;
    requiredAuthType: string;
    email: string;
    firstName: string;
    lastName: string;
    status: string;
    requirePasswordChange: string;
    lastPasswordChange: string;
    isApplication: string;
}

export interface AuthActiveRole {
    clientId: string;
    created: string;
    createdBy: string;
    id: number;
    isDefault: boolean;
    lastModified: string;
    lastModifiedBy: string;
    organization: AuthOrganization;
    person: Partial<AuthPerson>;
    role: AuthRole;
}

export interface AuthRole {
    clientId: string;
    created: string;
    description: string;
    id: number;
    lastModified: string;
    name: string;
}

export interface AuthOrganization {
    clientId: string;
    created: string;
    fullName: string;
    id: number;
    lastModified: string;
}
