import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'payments-table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TableFiltersComponent {
    @Input() info: string[][];
    @Input() showFilters: boolean;
    @Input() totalItems: number;

    @Input() set showFiltersBindOnce(value: boolean) {
        if (this.showFilters === undefined) {
            this.showFilters = value;
        }
    }
    

    toggleFilters() {
        this.showFilters = !this.showFilters;
    }
}
