import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    transform(value: any, length = 60): string {
        if (!value || value?.toString()?.length <= length) {
            return value?.toString();
        }
        return `${value?.toString()?.slice(0, length)}...`;
    }
}
