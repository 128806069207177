/** Header cell template container that adds the right classes and role. */
import { Component, Directive, HostBinding, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { CdkHeaderCell } from '@angular/cdk/table';

@Directive({
    selector: 'rui-header-cell, th[rui-header-cell]',
})
export class RuiHeaderCell extends CdkHeaderCell {
    @HostBinding('class') classes = 'mat-header-cell rui-header-cell align-top h-20';
    @HostBinding('attr.role') roles = 'columnheader';
}

@Directive({
    selector: `[rui-header-cell-label]`,
})
export class RuiHeaderCellLabel {
    @HostBinding('class') classes = 'flex items-center border-b border-gray-200 h-10';
}

@Directive({
    selector: `[rui-header-cell-filter]`,
})
export class RuiHeaderCellFilter {
    @HostBinding('class') classes = 'flex items-center h-10';
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'td[rui-table-loader-col]',
    encapsulation: ViewEncapsulation.None,
    template: `
        <div *ngFor="let item of items" class="border-b pr-4 py-2">
            <ngx-skeleton-loader [theme]="{ height: '10px', 'margin-bottom': '0' }"></ngx-skeleton-loader>
        </div>
    `,
})
export class RuiTableLoaderColComponent implements OnChanges {
    @Input() count = 5;
    items = [];

    @HostBinding('class') classes = 'table-cell p-0';

    constructor() {
        this.items.length = this.count;
    }

    ngOnChanges() {
        this.items.length = this.count;
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'tr[rui-table-loader]',
    encapsulation: ViewEncapsulation.None,
    template: `
        <ng-container *ngIf="loading; else noitems">
            <td rui-table-loader-col [count]="rows" *ngFor="let column of displayedColumns"></td>
        </ng-container>
        <ng-template #noitems>
            <td class="text-center p-4 border-b" [attr.colspan]="displayedColumns.length">
                <ng-content></ng-content>
            </td>
        </ng-template>
    `,
})
export class RuiTableLoaderComponent {
    @Input() loading = false;
    @Input() displayedColumns: string[] = [];
    @Input() rows = 5;

    @HostBinding('class') classes = 'mat-row';
}
