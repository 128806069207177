import {
    AfterViewInit,
    Component,
    ContentChild,
    Directive,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Breadcrumb } from './breadcrumb';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { FuseMediaWatcherService } from '../../@fuse/services/media-watcher';
import { takeUntil } from 'rxjs/operators';

/**
 * Page Drawer
 */

@Component({
    selector: `rui-page-drawer`,
    template: '<div class="py-10"><ng-content></ng-content></div>',
    styles: [
        `
            rui-page-drawer fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
                box-shadow: none !important;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RuiPageDrawer {
    @HostBinding('class') classes = 'block';
}

/**
 * Page Content
 */

@Directive({
    selector: `rui-page-content`,
})
export class RuiPageContent {
    @HostBinding('class') classes = 'flex flex-col';

    @HostBinding('class.max-w-3xl') get notFullwidth() {
        if (typeof this.fullwidth === 'string') {
            return this.fullwidth === 'fullwidth';
        } else {
            return !this.fullwidth;
        }
    }

    @Input() fullwidth: boolean | string = false;
}

/**
 * Page Main
 */

@Component({
    selector: 'rui-page',
    templateUrl: './page.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class RuiPageComponent implements AfterViewInit, OnInit, OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-auto min-w-0';

    @ContentChild(RuiPageDrawer) pageDrawer: RuiPageDrawer;
    @ContentChild(RuiPageContent, { read: ElementRef }) pageContent: ElementRef;

    @Input() breadcrumbs: Breadcrumb[] = [];
    @Input() showBackButton = false;
    @Input() backRoute = './..';

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened = true;
    canToggleDrawer = false;
    private _unsubscribeAll = new Subject();

    /**
     * Constructor
     */
    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService, private route: ActivatedRoute) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.route.fragment.subscribe((fragment) => {
                if (!fragment) return;
                const el = this.pageContent.nativeElement.querySelector(`#${fragment}`);
                setTimeout(() => {
                    el.scrollIntoView({ behavior: 'smooth' });
                }, 80);
            });
        }, 500);
    }

    ngOnInit(): void {
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('md')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}

/**
 *  Heading of the page.
 *  Uses content projection so you can supply your own wrappers
 */
@Component({
    selector: `rui-page-heading`,

    template: `
        <ng-container *ngIf="loading; else content">
            <ngx-skeleton-loader
                class="inline-block w-80"
                [theme]="{ 'vertical-align': 'middle', 'margin-bottom': 0 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #content>
            <ng-content></ng-content>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RuiPageHeading {
    @Input() loading = false;
}

/**
 *  Actions of the page in heading.
 *  Uses content projection so you can supply your own wrappers
 */
@Directive({
    selector: `rui-page-actions`,
})
export class RuiPageActions {
    @HostBinding('class') classes = 'space-x-3';
}

/**
 *  Optional top nav for the page. Can actually hold any content, not just nav.
 *  Uses content projection so you can supply your own wrappers
 */
@Directive({
    selector: `rui-page-topnav`,
})
export class RuiPageTopnav {
    @HostBinding('class') classes = 'flex-grow pt-4';
}

/**
 *  Optional top nav for the page. Can actually hold any content, not just nav.
 *  Uses content projection so you can supply your own wrappers
 */
@Directive({
    selector: `rui-page-footer`,
})
export class RuiPageFooter {
    @HostBinding('class') classes = 'sticky bottom-0 left-0 right-0 bg-white p-4 border-t flex items-center z-10';
}
