import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { IStorageService } from '../storage/storage.interface';
import { FormUtils } from './form-utils';
import { IListmanager, IListmanagerOptions } from './list-manager.interface';
import { ListManagerStore } from './list-manager.store';
import { Pagination } from './pagination';
import { ESortDirection, Sorting, SortState } from './sorting';

export class ClientSideListManager<TResponseContent extends Record<string, any>, TQueryParams = Record<string, unknown>>
    implements IListmanager<TResponseContent, TQueryParams>
{
    private original: TResponseContent[] = [];
    list: TResponseContent[] = this.original;

    pagination: Pagination;
    pageSizeOptions = [20, 50, 100, 500];
    sort: SortState;

    cachedFilter: Partial<TQueryParams> = {};

    storage: IStorageService = null;
    private store: ListManagerStore<TResponseContent, TQueryParams>;
    loading = false;

    private dataFn: () => Observable<TResponseContent[]>;

    constructor(dataFn: () => Observable<TResponseContent[]>, options?: IListmanagerOptions) {
        this.dataFn = dataFn;

        this.sort = options?.sort ?? new SortState('', ESortDirection.Asc);
        this.pagination = options?.pagination ?? new Pagination(0, 50);

        this.storage = options?.storage;

        this.store = new ListManagerStore(this, options);
        this.reloadStore();
    }

    reloadStore() {
        this.store.load();
        this.store.save();
    }

    handlePageChange(event: PageEvent): void {
        this.pagination.update(event);
        this.list = this.filtered(this.original);
        this.list = this.sorted(this.list);
        this.list = this.paginated(this.list);
        this.store.save();
    }

    handleSortChange(event: Sort): void {
        this.pagination.page = 0;

        this.sort.update(event);
        this.list = this.filtered(this.original);
        this.list = this.sorted(this.list);
        this.list = this.paginated(this.list);
        this.store.save();
    }

    handleFilterChange(): void {
        this.list = this.filtered(this.original);
        this.list = this.sorted(this.list);
        this.list = this.paginated(this.list);
        this.store.save();
    }

    get approximatePageSize() {
        if (!this.list || this.list.length === 0) {
            return 5;
        }
        return this.list.length;
    }

    get isFilterEmpty() {
        return this.filter === null || !Object.keys(this.filter).length;
    }

    get isFilterNotEmpty(): boolean {
        return !this.isFilterEmpty;
    }

    get filter(): Partial<TQueryParams> {
        return this.cachedFilter;
    }

    set filter(value: Partial<TQueryParams>) {
        this.pagination.page = 0;
        this.cachedFilter = value;
        this.handleFilterChange();
    }

    fetchData() {
        this.loading = true;
        this.list = [];
        this.dataFn().subscribe((data: TResponseContent[]) => {
            this.original = JSON.parse(JSON.stringify(data));
            this.list = data;

            this.pagination.totalElements = this.list.length;

            this.list = this.filtered(this.original);
            this.list = this.sorted(this.list);
            this.list = this.paginated(this.list);
            this.loading = false;
        });
    }

    sorted(array: TResponseContent[]): TResponseContent[] {
        return Sorting.sortByKey<TResponseContent>(array, this.sort.key, this.sort.direction);
    }

    paginated(array: TResponseContent[]): TResponseContent[] {
        const page_size = this.pagination.size;
        const page_number = this.pagination.page;
        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }

    filtered(array: TResponseContent[]): TResponseContent[] {
        if (this.filter === null) {
            this.pagination.totalElements = array.length;
            return array;
        }
        const activeFilter = FormUtils.notEmptyValuesOnly(this.filter);

        if (!Object.keys(activeFilter).length) {
            this.pagination.totalElements = array.length;
            return array;
        }

        const isMatching = (filterKey: string, item: TResponseContent) =>
            this.getNested(filterKey, item)
                .toString()
                .toLowerCase()
                .startsWith(activeFilter[filterKey].toString().toLowerCase());

        const filteredContent = array.filter((item) => {
            return Object.keys(activeFilter).every((key) => isMatching(key, item));
        });

        this.pagination.totalElements = filteredContent.length;

        return filteredContent;
    }

    private getNested(key: string, item: TResponseContent) {
        const keys = key.split('.');

        let result = item;
        keys.forEach((key) => {
            result = result[key];
        });
        return result;
    }
}
