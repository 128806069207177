import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { ERoles } from './roles.enum';

@Directive({
    selector: '[paymentsHasRoles]',
})
export class HasRolesDirective implements OnInit {
    @Input('paymentsHasRoles') args!: ERoles[];

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private authorization: AuthorizationService
    ) {}

    ngOnInit(): void {
        if (!this.args || this.authorization.hasSomeRole(this.args)) {
            return this.show();
        }

        return this.hide();
    }

    show() {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    hide() {
        this.viewContainer.clear();
    }
}
