import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [],
    imports: [CommonModule, FuseConfirmationModule, TranslateModule, MatButtonModule],
})
export class UserActivityModule {}
