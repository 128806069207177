import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagPipe } from './feature-flag.pipe';

@NgModule({
    declarations: [FeatureFlagPipe],
    imports: [CommonModule],
    exports: [FeatureFlagPipe],
})
export class LaunchDarklyModule {}
