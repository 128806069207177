import { NgModule } from '@angular/core';
import { Auth0ClientService, AuthConfigService } from '@auth0/auth0-angular';
import { of } from 'rxjs';

@NgModule({
    providers: [
        {
            provide: AuthConfigService,
            useValue: { domain: '', clientId: '' },
        },
        {
            provide: Auth0ClientService,
            useValue: { checkSession: () => of(null)},
        },
    ],
})
export class Auth0TestingModule {}
