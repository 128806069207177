import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostBinding, Input } from '@angular/core';
import { NotificationsService } from '@ridango/ui/notifications';

@Component({
    selector: 'payments-copy',
    template: `
        <button [disabled]="!content" (click)="copy()">
            <mat-icon
                color="primary"
                class="fuse-horizontal-navigation-item-icon"
                ngClass="heroicons_outline:square-2-stack"
                svgIcon="heroicons_outline:square-2-stack"></mat-icon>
        </button>
    `,
})
export class CopyComponent {
    @HostBinding('class') classes = 'h-6 w-6 mx-2 -my-1';

    @Input() content!: string;

    constructor(private clipboard: Clipboard, private notificationService: NotificationsService) {}

    copy() {
        this.clipboard.copy(this.content);
        this.notificationService.addSuccess('Copied to clipboard', { duration: 2000 });
    }
}
