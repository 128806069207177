import { Sort, SortDirection } from '@angular/material/sort';

export enum ESortDirection {
    Asc = 'asc',
    Desc = 'desc',
    None = '',
}

export class SortState {
    key: string;
    direction: ESortDirection;

    constructor(key: string, direction: ESortDirection = ESortDirection.Asc) {
        this.key = key;
        this.direction = direction;
    }

    update(event: Sort) {
        this.key = event?.active;
        this.direction = this.materialSortDirectionAdapter(event?.direction);
    }

    private materialSortDirectionAdapter(direction: SortDirection): ESortDirection {
        switch (direction) {
            case 'asc':
                return ESortDirection.Asc;
            case 'desc':
                return ESortDirection.Desc;
            default:
                return ESortDirection.None;
        }
    }
}

export class Sorting {
    /**
     * @description Sorts array by key. If argument "key" has one or more "." character,
     * then compares attributes inside nested object. eg. key -> "parent.child.parameter"
     * @param inputArray        Array consisting of type variable <T>
     * @param key               Sort key. Accepts nested keys.
     * @param direction         Sort direction
     */
    public static sortByKey<T extends Record<string, any>>(
        inputArray: T[],
        key: string,
        direction = ESortDirection.Asc
    ) {
        if (key.includes('.')) {
            return Sorting.sortByDotNotationKey<T>(inputArray, key, direction);
        }

        if (direction === ESortDirection.Asc) {
            return inputArray.sort((a, b) => Sorting.sortByComparison(a[key], b[key]));
        }
        return inputArray.sort((a, b) => Sorting.sortByComparison(a[key], b[key]) * -1);
    }

    private static sortByDotNotationKey<T>(inputArray: T[], key: string, direction = ESortDirection.Asc) {
        if (direction === ESortDirection.Asc) {
            return inputArray.sort((a, b) =>
                Sorting.sortByComparison(Sorting.getDescendantProperty(a, key), Sorting.getDescendantProperty(b, key))
            );
        }

        return inputArray.sort(
            (a, b) =>
                Sorting.sortByComparison(Sorting.getDescendantProperty(a, key), Sorting.getDescendantProperty(b, key)) *
                -1
        );
    }

    private static sortByComparison(first: unknown, second: unknown) {
        const a = Sorting.transformToLowerCase(first);
        const b = Sorting.transformToLowerCase(second);

        if (a === b) return 0;
        if (a === null) return 1;
        if (b === null) return -1;

        if (typeof a === "string" && typeof b === "string") {
            return a.localeCompare(b, "en", { numeric: true });
        }

        if (a < b) return -1;
        if (a > b) return 1;

        return 0;
    }

    private static getDescendantProperty = (obj, path: string) =>
        path.split('.').reduce((acc, part) => acc && acc[part], obj);

    private static transformToLowerCase = (value) => (typeof value === 'string' ? value.toLowerCase() : value);
}
