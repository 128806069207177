import { Pipe, PipeTransform } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { ERoles } from './roles.enum';

@Pipe({
    name: 'isAuthorized',
})
export class IsAuthorizedPipe implements PipeTransform {
    constructor(private authorizationService: AuthorizationService) {}
    transform(roles: ERoles[]): boolean {
        return this.authorizationService.hasSomeRole(roles);
    }
}
